<script lang="ts">
import { useThrottleFn } from '@vueuse/core'
import locales from '~/assets/data/locales.json'

export default {
  layout: 'default',
}
</script>

<script setup lang="ts">
const route = useRoute()
const showArrow = ref(false)
const { $config } = useNuxtApp()
const newUrl = useSetSubdomain(useGetSubdomain()) + route.path
const i18nHead = useLocaleHead({
  addSeoAttributes: true,
})

const alternateLinks = locales.map((locale: any) => ({ rel: 'alternate', hreflang: locale.lang, href: useSetSubdomain(locale.region) }))

useHead({
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  titleTemplate: (title: string) => computed(() => `${route.meta.title || title}`).value,
  meta: [
    { property: 'og:title', content: computed<any>(() => route.meta.title) },
    { name: 'twitter:title', content: computed<any>(() => route.meta.title) },
  ],
  link: [
    { rel: 'canonical', href: computed(() => newUrl) },
    // { rel: 'alternate', hreflang: 'en', href: config.public.SITEMAP_HOST },
    // ...alternateLinks,
  ],
})

const handleWindowScroll = useThrottleFn((event) => {
  showArrow.value = window.scrollY > 550
}, 300)

onMounted(() => {
  window.addEventListener('scroll', handleWindowScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleWindowScroll)
})

const handleClickArrow = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

useSchemaOrg([
  {
    '@type': 'WebPage',
    '@id': `${useSetSubdomain(useGetSubdomain())}${route.fullPath}#webpage`,
    'url': `${useSetSubdomain(useGetSubdomain())}${route.fullPath}`,
    'about': {
      '@id': `${useSetSubdomain(useGetSubdomain())}/#identity`,
    },
    'isPartOf': {
      '@id': `${useSetSubdomain(useGetSubdomain())}/#website`,
    },
    'potentialAction': [{
      '@type': 'ReadAction',
      'target': `${useSetSubdomain(useGetSubdomain())}${route.fullPath}`,
    }],
    'primaryImageOfPage': {
      '@id': `${useSetSubdomain(useGetSubdomain())}/#logo`,
    },
  },
  defineOrganization({
    name: 'Epicareer',
    logo: '/images/logo-lg.png',
    sameAs: [
      'https://twitter.com/epicareer',
      'https://www.instagram.com/epicareer/',
      'https://www.facebook.com/epicareer',
    ],
  }),
  defineWebSite({
    name: 'Epicareer',
  }),
  // defineWebPage(),
])

const selectedPath = ['/settings', '/salary-report', '/search', '/companies', '/faq', '/about', '/contact', '/terms-of-service', '/privacy-policy', '/job']
</script>

<template>
  <div :class="`min-h-screen relative text-sm text-gray-500 flex flex-col ${route.path.includes('career-guide') ? 'bg-[#f3f4f6]' : ''}`">
    <BaseOrganismsMainNavbar />
    <main :class="`${selectedPath.some(routePath => route.path === routePath || route.path.includes(routePath) && route.path !== '/page/salary-report') ? 'bg-gray-50' : ''} ${route.path === '/search' ? '' : 'pb-20 lg:pb-40'} `">
      <slot />
    </main>
    <BaseOrganismsMainFooter class="mt-auto" />

    <ClientOnly>
      <div
        v-show="showArrow"
        class="fixed lg:hidden bottom-6 right-6 flex items-center justify-center z-29 w-10 h-10 rounded-full border border-[#5843e5] bg-white transition-all cursor-pointer"
        @click.prevent="handleClickArrow"
      >
        <Icon name="heroicons:arrow-up-solid" class="w-[20px] h-[20px] text-[#5843e5]" />
      </div>
    </ClientOnly>
  </div>
</template>
